;(function($){

    function Instagram() {

        var _     = this;
        
        _.show    =  "9";
        _.userId  = "0fa90d252d2a4021b2df6cebb6a3950b";
        _.token   = "552684945.0fa90d2.f82baddfd48e441f9df01a620f11f52f";
        _.hashtag = "morrodosventos";
        
        _.get();
    }

    Instagram.prototype.get =  function() {
        var _ =  this;

        $(".instagram-box").instagram({
            show: _.show,
            userId:  _.userId,
            accessToken:  _.token,
            hashtag: _.hashtag
        });
    };

   	new Instagram();

}(jQuery));
 
 
 
 
