;(function($){

    function Slides() {
   	 	var _ =  this;

		 _.elementPresentationSlider = $('.slider-section .slider');

        _.elementPresentationSlider.on('init', function(){
            if($("video").length > 0) {
                $("video").get(0).play();
            }
        });

        _.elementPresentationSlider.slick({
              dots: false,
			  arrows: false,
			  infinite: true,
			  speed: 500,
			  fade: true,
			  cssEase: 'linear',
			  autoplay: true,
	  		  autoplaySpeed: 3500
        });     

        _.elementPresentationSlider.on('afterChange', function(event, slick, currentSlide){
            
            var currentVideo = $(slick.$slides[currentSlide]).find('video');            
            
            if (currentVideo.length > 0) {
                _.elementPresentationSlider.slick('slickPause');
                $(currentVideo).get(0).play();

                if(slick.slideCount > 1) {
                    currentVideo.on('ended',function() {  
                        _.elementPresentationSlider.slick('slickPlay');
                    });
                }

                _.elementPresentationSlider.on("mouseover", function(){
                    //$(currentVideo).get(0).pause();
                });

                _.elementPresentationSlider.on("mouseout", function(){
                    //$(currentVideo).get(0).play();
                });

                $(window).on("scroll", function (event) {

                    if ($(window).scrollTop() > 600) {
                        $(currentVideo).get(0).pause();
                    } else {
                        $(currentVideo).get(0).play();
                    }
                });
            }           
        });

		$('.depositions .carrossel, .tourism .carrossel, .restaurant .carrossel, .abouts .carrossel, .team .carrossel').slick({
		  dots: true,
		  arrows: false,
		  infinite: true,
		  speed: 500,
		  fade: true,
		  cssEase: 'linear',
		  autoplay: true,
  		  autoplaySpeed: 3000
		});

		
		$( ".weddings .carrossel" ).each(function( index, value) {
		    $(value).slick({
			  dots: true,
			  arrows: false,
			  infinite: true,
			  speed: 500,
			  fade: true,
			  cssEase: 'linear',
			  autoplay: true,
	  		  autoplaySpeed: 3000
			});		  
		});
    }
    
   	new Slides();

}(jQuery));
 
 
 
 
